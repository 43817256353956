<template>
  <div v-if="loggedIn" class="w-full text-center">
    <Header title="Willkommen bei AAuth" :loggedIn="loggedIn" />
  <div class="w-full md:w-2/3 inline-block">
    <div id="nav" class="pl-5 text-left inline-block">
      <span v-bind:class="{selected2: showAccount}" class="navigation2" @click="setAccount">Dein Account</span>
    <span v-bind:class="{selected2: showContacts}" class="navigation2" @click="setContacts">Kontakte</span>
    <span v-bind:class="{selected2: showIdentities}" class="navigation2" @click="setIdentities">Identitäten</span>
    <div class="text-center inline-block">
    <span v-if="showBusinessNavigation" v-bind:class="{selected2: showBusiness}" class="navigation2" @click="setBusiness">Für Unternehmen</span><br>
    <span v-if="showBusiness" v-bind:class="{subSelected: showOIDC}" class="subNavigation" @click="setOIDC">OIDC</span>
    <span v-if="showBusiness" v-bind:class="{subSelected: showMFA}" class="subNavigation" @click="setMFA">MFA</span>
    </div>
    </div>
    <div class="settingscontent mt-5" style="display: inline-block;  text-align: center; width: 100%">
    <div class="w-full bg-white inline-block shadow-lg rounded-3xl p-5">
    <Account v-if="showAccount"/>
    <Contacts :contacts="contacts" v-if="showContacts"/>
    <Identities :contacts="contacts" v-if="showIdentities"/>
    <Company v-if="showBusiness && showOIDC"/>
    <MFA v-if="showBusiness && showMFA"/>
    </div>
    </div>
    </div>
  </div>
  <div v-else class="lds-dual-ring-lg"></div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Account from '@/components/Account.vue'
import Contacts from '@/components/Contacts.vue'
import Company from '@/views/Company.vue'
import MFA from '@/views/MFA.vue'
import Identities from '@/components/Identities.vue'
const AAUth = require('@/AAuth.js')

export default {
  name: 'Me',
  components: {
    Header,
    MFA,
    Company,
    Identities,
    Contacts,
    Account
  },
  data () {
    return {
      loggedIn: false,
      companies: [],
      showAccount: true,
      showBusiness: false,
      showBusinessNavigation: false,
      showIdentities: false,
      showContacts: false,
      showOIDC: false,
      showMFA: false,
      contacts: [],
      counter:0
    }
  },
  methods: {
    hideAll (reset=true) {
      if(reset) this.counter=0
      this.showAccount = false
      this.showBusiness = false
      this.showContacts = false
      this.showIdentities = false
    },
    setAccount () {
      this.hideAll(false)
      this.showAccount = true
      this.counter++;
      if(this.counter===5){
        this.showBusinessNavigation=true
      }
    },
    setBusiness () {
      this.hideAll()
      this.showBusiness = true
      this.showOIDC=true
    },
    setIdentities () {
      this.hideAll()
      this.showIdentities = true
    },
    setShared () {
      this.hideAll()
      this.showShared = true
    },
    setContacts () {
      this.hideAll()
      this.showContacts = true
    },
    setOIDC () {
      this.showMFA=false
      this.showOIDC = true
    },
    setMFA () {
      this.showOIDC=false
      this.showMFA = true
    },
     setContacts () {
      this.hideAll()
      this.showContacts = true
    },
    getContacts () {
      const vm = this
      AAUth.getContactsForUser((response) => {
        if (response.status !== 'error') {
          vm.contacts = response
        }
      })
    },
    checkLoginStatus (cb) {
      const vm = this
      AAUth.isLoggedIn((validLogin) => {
        if (!validLogin) {
          window.location = '/me/login'
        } else {
          vm.getContacts()
        }
        vm.loggedIn = validLogin
        cb(vm)
      })
    }
  },
  created: function () {
    this.checkLoginStatus(() => {})
  }
}
</script>
<style>
#logo {
  width: 250px;
  height: 250px
}
html {
  background: #f1f1f1
}
.navigation{
  margin-top: 5px;
  font-weight: bold;
  display:block;
  cursor: pointer;
}

.navigation2, .subNavigation{
  margin-right: 20px;
  margin-top: 10px;
  font-weight: bold;
  cursor: pointer;
  vertical-align: middle;
}
.subNavigation{
  margin-top: 2px;
  font-size: 10px;
}
.selected{
  color: #e0e000;
}
.selected2 {
  margin-top: 5px;
  border-bottom: 4px solid #ffff00;
  font-size: 25px;
  margin-top: 0px;
}
.subSelected{
  border-bottom: 2px solid #ffff00;
  font-size: 16px;
}
</style>
