<template>
  <div class="section">
    <span class="font-bold text-lg">Nickname</span><br />
    <div style="display: flex; width: 100%; justify-content: space-between">
      <input
        style="border: 1px solid gray; border-radius: 5px; padding: 5px"
        @blur="saveNickname"
        v-model="user.nickname"
        placeholder="Choose Nickname"
      />
      <button
        class="inline-block border-2 border-red-600"
        onclick="window.location='/me/recover'"
      >
        Show recovery Code (once!)
      </button>
    </div>
    <table v-if="addresses.length > 0" id="userInfo">
      <thead>
        <th style="width: 80%">
          Verbundene Mailadressen
          <span v-if="successtext" class="font-bold text-green-500 text-sm">{{
            successtext
          }}</span>
        </th>
        <th style="text-align: center">Bestätigt</th>
        <th style="width: 15%; text-align: center">Optionen</th>
      </thead>
      <tbody id="userInfoBody">
        <template v-for="address in addresses" :key="address.mail">
          <tr>
            <td>{{ address.address }}</td>
            <td style="text-align: center">
              <font-awesome-icon
                v-if="address.verified"
                icon="fa-check"
              /><font-awesome-icon v-else icon="fa-times" />
            </td>
            <td style="text-align: center">
              <font-awesome-icon
                class="mx-2"
                v-if="!address.verified"
                :id="address.uuid"
                @click="verifyMail"
                icon="fa-user-check"
              /><font-awesome-icon
                class="mx-2"
                :id="address.address"
                @click="remove"
                icon="fa-trash-alt"
              />
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <span class="inline-block mt-5 font-bold text-sm">Neue Mailadresse</span>
    <br /><input
      class="border-b border-black p-2 w-1/3"
      v-model="emailAddress"
      placeholder="Neue Mailadresse"
    /><button class="ml-3" @click="addEmailAddress()">Hinzufügen</button>
    <span class="block font-bold text-red-500 text-sm" v-if="errortext">{{
      errortext
    }}</span>
  </div>
</template>

<script>
const AAuth = require("@/AAuth.js");

export default {
  name: "Account",
  data() {
    return {
      addresses: [],
      emailAddress: "",
      user: {
        nickname: "",
      },
      errortext: "",
      successtext: "Verfiziierung versendet",
    };
  },
  methods: {
    verifyMail(event) {
      AAuth.verifyMail(event.target.parentElement.id, (response) => {
        if (response.status === "success") {
          successtext = "Verifizierungsmail versendet";
        }
      });
    },
    saveNickname(event) {
      const vm = this;
      AAuth.editUser({ nickname: this.user.nickname }, () => {
        vm.getAccount();
      });
    },
    remove(event) {},
    getAccount() {
      const vm = this;
      AAuth.getAccount((userInfo) => {
        vm.user = userInfo;
      });
    },
    getEmailAddresses() {
      const vm = this;
      AAuth.getEmailAddresses((addresses) => {
        vm.addresses = addresses;
      });
    },
    addEmailAddress() {
      const vm = this;
      AAuth.addEmail(this.emailAddress, (response) => {
        if (response.status === "success") {
          vm.emailAddress = "";
          this.getEmailAddresses();
          AAuth.verifyMail(response.mailId, (response) => {
            if (response.status === "success") {
              vm.successtext = "Verifizierungsmail versendet";
            }
          });
        } else {
          vm.errortext = response.msg;
        }
      });
    },
  },
  created: function () {
    this.getAccount();
    this.getEmailAddresses();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 10px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
svg {
  cursor: pointer;
  width: 30px;
  height: 30px;
}
#userInfo {
  margin-top: 20px;
  width: 100%;
}
.user-shield,
.info-circle,
.code {
  height: 130px;
  width: 130px;
  cursor: pointer;
  color: #393939;
}
.share-square,
.trash,
.edit {
  height: 20px;
  width: 20px;
  color: #393939;
  cursor: pointer;
}
.navIcon {
  padding: 30px;
  display: inline-block;
}

table {
  border-collapse: separate;
}
tr {
  margin: 5px;
}

td {
  padding: 5px;
  margin: 0px;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
}
td:first-child {
  border-left: solid 1px gray;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
td:last-child {
  border-right: solid 1px gray;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
h3 {
  display: block;
}
.section {
  display: inline-block;
  vertical-align: top;
  text-align: left;
  box-sizing: border-box;
  width: 100%;
}
</style>
