
<template>
  <Header title="Kontakt hinzufügen"/>
  <div class="text-left -mt-3 font-bold px-10 cursor-pointer" onclick="window.location='/me/'">
    <font-awesome-icon icon="fa-arrow-left"/><span class="inline ml-4 underline">Zurück zum Profil</span>
    </div>
  <QR v-if="qrActive" :value="qrcode" :size="200"></QR>
  <div v-if="!qrActive" class="text-center">
    <div class="inline-block text-left">
    <span class="font-bold">Nickname:</span><br>
  <input class="px-2 py-1" v-model="nickname" /><br>
  <div class="w-full text-center">
  <button class="mt-4 bg-white border border-green-400 p-2 px-4 font-bold rounded-lg" @click="addContact">Kontakt hinzufügen</button>
  </div>
  </div>
  </div>
</template>
<script>

import QR from '@/components/QR.js'
import Header from '@/components/Header.vue'
const AAUth = require('@/AAuth.js')
export default {
  name: 'AddContact',
  components: {
    QR,
    Header
  },
  data () {
    return {
      qrcode: '',
      nickname: '',
      qrActive: true,
      contactCode: ''
    }
  },
  methods: {
    startSocket () {
      const comp = this
      this.connection = new WebSocket(process.env.VUE_APP_WS_URL+'/contact')
      this.connection.onmessage = function (event) {
        let data=JSON.parse(event.data)
        if (data.type == "contact") {
          comp.qrcode = 'aauth:'+ data.type+":"+data.token
        } else if (data.type=="contactCode") {
          comp.contactCode = data.token
          comp.qrActive = false
          comp.nickname = data.nickname
        }
      }
    },
    addContact () {
      AAUth.addContact(this.contactCode, this.nickname, (request) => {
        const jsonresponse = JSON.parse(request.responseText)
        if (jsonresponse.status === 'success') {
          window.location = '/me'
        }
      })
    }
  },
  created: function () {
    this.startSocket()
  }
}
</script>
