import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './style.css'

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faArrowLeft, faCheck, faCode, faInfoCircle, faPlusCircle, faRightToBracket, faShare, faTimes, faTrashAlt, faUserCheck, faUserShield } from '@fortawesome/free-solid-svg-icons';
// App.component('icon', icons)


library.add(faInfoCircle);
library.add(faUserShield);
library.add(faCode);
library.add(faRightToBracket)
library.add(faTimes)
library.add(faCheck)
library.add(faUserCheck)
library.add(faShare)
library.add(faTrashAlt)
library.add(faPlusCircle)
library.add(faArrowLeft)



const app = createApp(App).component("font-awesome-icon", FontAwesomeIcon).use(router)
app.mount('#app')
