<template>
<div id="centerwrapper">
     <h3 class="font-bold text-xl mt-5">MFA-Clients</h3>
         <template v-for="client in oidcclients" :key="client.name">
           <div class="mt-4">
             <span class="font-bold text-2xl">{{client.name}}</span><button class="bg-white px-2 font-bold text-sm border-2 border-red-500 rounded-lg ml-5">Entfernen</button>
             <div class="ml-3 mt-2">
               <table>
                 <tr><td class="font-bold">Client ID:</td><td style="font-weight: bold">{{client.client_id}}</td></tr>
                 <tr><td class="font-bold">Client Secret:</td><td style="font-weight: bold">{{client.client_secret}}</td></tr>
                 <tr><td class="font-bold">JS-URL</td><td style="font-weight: bold">https://api.aauth.me/mfa/js/1128123-1238712412-12361</td></tr>
              </table>
             </div>
           </div>
         </template>
         <div class="inline-block mt-2 shadow-lg rounded-lg p-3">
          <span class="font-bold">Name:</span><br>
          <input v-model="newClientName" placeholder="Meine Webanwendung">
          <button class="px-2 py-1 bg-white border border-green-400 rounded-lg font-bold" @click="createClient()">Client erstellen</button>
           </div>
</div>
</template>

<script>
// @ is an alias to /src
const AAUth = require('@/AAuth.js')

export default {
  name: 'Home',
  components: {
  },
  data () {
    return {
      loggedIn: true,
      oidcclients: [],
      newClientName: '',
      registration: 'OPEN',
      redirect: ''
    }
  },
  methods: {
    getOIDCClients () {
      const vm = this
      AAUth.getOIDCClients((clients) => {
        vm.oidcclients = clients
      })
    },
    createClient () {
      const vm = this
      AAUth.createClient({
        name: vm.newClientName,
        registration: vm.registration,
        redirect_uri: vm.redirect
      }, (response) => {
        if (response.status === 'success') {
          vm.getOIDCClients()
          vm.newClientName = ''
          vm.redirect = ''
          vm.registration = 'OPEN'
        }
      })
    }
  },
  created: function () {
    this.getOIDCClients()
  }
}
</script>
<style>
#logo {
  width: 250px;
  height: 250px
}
html {
  background: #f1f1f1
}
table{
  display: inline-block;
}
#oidctable td {
  border: 1px solid black;
}
td{
  border: 1px solid black;
  padding: 2px;
}
button{
  display: inline-block;
}
#centerwrapper{
  max-width: 80%;
  text-align:left;
  display: inline-block;
  background: white;
  border-radius: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 30px;
}
.deleteClient, .createClient{
  margin-left: 15px;
  background: red;
  padding: 5px;
  font-weight: bold;
  border: unset;
  border-radius: 5px;
}
.createClient{
  background: green;
}
a{
  color:unset;
}
</style>
