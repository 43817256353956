import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'
import Company from '../views/Company.vue'
import Recover from '../views/Recover.vue'
import Consent from '../views/Consent.vue'
import AddContact from '../views/AddContact.vue'
import CodeBundle from '../views/CodeBundle.vue'
import Me from '../views/Me.vue'

const routes = [{
  path: '/me',
  name: 'Me',
  component: Me
},
{
  path: '/me/login',
  name: 'Login',
  component: Login
},
{
  path: '/me/recover',
  name: 'Recover',
  component: Recover
},
{
  path: '/me/consent',
  name: 'Consent',
  component: Consent
},
{
  path: '/me/addContact',
  name: 'AddContact',
  component: AddContact
},
{
  path: '/me/codeBundle',
  name: 'CodeBundle',
  component: CodeBundle
},
{
  path: '/me/company/:uuid',
  name: 'Company',
  component: Company
},
{
  path: '/about',
  name: 'About',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () =>
    import(/* webpackChunkName: "about" */ '../views/About.vue')
},
{
  path: "/:catchAll(.*)",
  name: 'Me',
  component: Me
}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
