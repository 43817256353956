
<template>
<Header title="Recovery Code"/>
<h1 v-if="qrActive">This page cannot be shown again! Store the downloaded qr code safely!</h1>
  <QR v-if="qrActive" :value="qrcode" :size="200"></QR>
  <h4>{{qrcode}}</h4>
  <a ref="downloadButton" :href="qrdata" download hidden></a>
</template>
<script>
import Header from '@/components/Header.vue'

import QR from '@/components/QR.js'
const AAUth = require('@/AAuth.js')
export default {
  name: 'Recover',
  components: {
    QR,
    Header
  },
  data () {
    return {
      qrcode: '',
      nickname: '',
      qrActive: false,
      qrdata: ''
    }
  },
  methods: {
    getCode () {
      const comp = this
      AAUth.getRecoveryCode((response) => {
        if (response.status === 'success') {
          comp.qrActive = true
          comp.qrcode = 'aauth:recovery:' + response.recoveryCode
          console.log('qrcode is now ' + comp.qrcode)
          setTimeout(() => {
            comp.$refs.downloadButton.href = document.getElementsByTagName('img')[1].src
            comp.$refs.downloadButton.click()
          }, 100)
        } else {
          comp.qrcode = response.msg
          setTimeout(() => {
            window.location = '/me'
          }, 2000)
        }
      })
    }
  },
  created: function () {
    this.getCode()
  }
}
</script>
