/* eslint-disable standard/no-callback-literal */
var host=process.env.VUE_APP_HTTP_URL+"/"

function login (token, state, callback) {
  var logIn = new XMLHttpRequest()
  logIn.open('POST', host + 'api/auth/token')
  logIn.withCredentials = true
  logIn.setRequestHeader('Content-Type', 'application/json')
  logIn.addEventListener('load', function (event) {
    callback(logIn)
  })
  logIn.send(JSON.stringify({
    grant_type: 'spa_token',
    state: state,
    spa_token: token
  }))
}
function loginWithCodeBundle (code, pin, callback) {
  var req = new XMLHttpRequest()
  req.open('POST', host + 'api/auth/token')
  req.withCredentials = true
  req.setRequestHeader('Content-Type', 'application/json')
  req.addEventListener('load', function (event) {
    callback(JSON.parse(req.responseText))
  })
  req.send(JSON.stringify({
    grant_type: 'code_bundle',
    code_bundle: code,
    pin: pin
  }))
}
function getConsentInformation (consentRequest, callback) {
  var req = new XMLHttpRequest()
  req.open('GET', host + 'api/auth/consent?cr=' + consentRequest)
  req.withCredentials = true
  req.addEventListener('load', function (event) {
    callback(JSON.parse(req.responseText))
  })
  req.send()
}
function editUser (changes, callback) {
  var req = new XMLHttpRequest()
  req.open('PUT', host + 'api/user')
  req.setRequestHeader('Content-Type', 'application/json')
  req.withCredentials = true
  req.addEventListener('load', function (event) {
    callback(JSON.parse(req.responseText))
  })
  req.send(JSON.stringify(changes))
}
function consent (address, clientId, callback) {
  var req = new XMLHttpRequest()
  req.open('POST', host + 'api/auth/consent')
  req.withCredentials = true
  req.setRequestHeader('Content-Type', 'application/json')
  req.addEventListener('load', function (event) {
    callback(JSON.parse(req.responseText))
  })
  req.send(JSON.stringify({
    id: address,
    client_id: clientId
  }))
}
function isLoggedIn (callback) {
  var getLoggedInState = new XMLHttpRequest()
  getLoggedInState.open('GET', host + 'api/auth')
  getLoggedInState.withCredentials = true
  getLoggedInState.addEventListener('load', function (event) {
    const jsonresponse = JSON.parse(getLoggedInState.responseText)
    if (jsonresponse.status === 'authenticated') {
      callback(true)
    } else if (jsonresponse.status === 'not authenticated') {
      callback(false)
    }
  })
  getLoggedInState.send()
}
function shareIdentity (id, contact, callback) {
  var req = new XMLHttpRequest()
  req.open('POST', host + 'api/user/share')
  req.setRequestHeader('Content-Type', 'application/json')
  req.withCredentials = true
  req.addEventListener('load', function (event) {
    callback(JSON.parse(req.responseText))
  })
  req.send(JSON.stringify({
    idId: id,
    contactId: contact
  }))
}

function deleteShare (shareId, callback) {
  var req = new XMLHttpRequest()
  req.open('DELETE', host + 'api/user/share/' + shareId)
  req.withCredentials = true
  req.addEventListener('load', function (event) {
    callback(JSON.parse(req.responseText))
  })
  req.send()
}

function getAccount (callback) {
  var userInfo = new XMLHttpRequest()
  userInfo.open('GET', host + 'api/user')
  userInfo.withCredentials = true
  userInfo.addEventListener('load', function (event) {
    callback(JSON.parse(userInfo.responseText))
  })
  userInfo.send()
}
function getEmailAddresses (callback) {
  var userInfo = new XMLHttpRequest()
  userInfo.open('GET', host + 'api/user/emails')
  userInfo.withCredentials = true
  userInfo.addEventListener('load', function (event) {
    callback(JSON.parse(userInfo.responseText))
  })
  userInfo.send()
}

function getContactsForUser (callback) {
  var contacts = new XMLHttpRequest()
  contacts.open('GET', host + 'api/user/contacts')
  contacts.withCredentials = true
  contacts.addEventListener('load', function (event) {
    callback(JSON.parse(contacts.responseText))
  })
  contacts.send()
}
function getIDsForUser (callback) {
  var contacts = new XMLHttpRequest()
  contacts.open('GET', host + 'api/user/ids')
  contacts.withCredentials = true
  contacts.addEventListener('load', function (event) {
    callback(JSON.parse(contacts.responseText))
  })
  contacts.send()
}
function addContact (contactCode, nickname, callback) {
  var newContact = new XMLHttpRequest()
  newContact.open('POST', host + 'api/user/contacts')
  newContact.withCredentials = true
  newContact.setRequestHeader('Content-Type', 'application/json')
  newContact.addEventListener('load', function (event) {
    callback(newContact)
  })
  newContact.send(JSON.stringify({
    contactCode: contactCode,
    nickname: nickname
  }))
}
function verifyMail (id, callback) {
  var req = new XMLHttpRequest()
  req.open('POST', host + 'api/user/verifyMail')
  req.withCredentials = true
  req.setRequestHeader('Content-Type', 'application/json')
  req.addEventListener('load', function (event) {
    callback(JSON.parse(req.responseText))
  })
  req.send(JSON.stringify({
    id: id
  }))
}
function addEmail (address, callback) {
  var req = new XMLHttpRequest()
  req.open('POST', host + 'api/user/emails')
  req.withCredentials = true
  req.setRequestHeader('Content-Type', 'application/json')
  req.addEventListener('load', function (event) {
    callback(JSON.parse(req.responseText))
  })
  req.send(JSON.stringify({
    address: address
  }))
}

function getOIDCClients (cb) {
  var oidcClients = new XMLHttpRequest()
  oidcClients.open('GET', host + 'api/user/oidcclients')
  oidcClients.withCredentials = true
  oidcClients.addEventListener('load', function (event) {
    cb(JSON.parse(oidcClients.responseText))
  })
  oidcClients.send()
}
function logout (cb) {
  var logoutRequest = new XMLHttpRequest()
  logoutRequest.open('GET', host + 'api/auth/logout')
  logoutRequest.withCredentials = true
  logoutRequest.addEventListener('load', function (event) {
    cb()
  })
  logoutRequest.send()
}
function createClient (client, cb) {
  var newClient = new XMLHttpRequest()
  newClient.open('POST', host + 'api/user/oidcclients')
  newClient.withCredentials = true
  newClient.setRequestHeader('Content-Type', 'application/json')
  newClient.addEventListener('load', function (event) {
    cb(JSON.parse(newClient.responseText))
  })
  newClient.send(JSON.stringify(
    client
  ))
}
function getRecoveryCode (cb) {
  var recoveryCodeRequest = new XMLHttpRequest()
  recoveryCodeRequest.open('GET', host + 'api/auth/recoveryCode')
  recoveryCodeRequest.withCredentials = true
  recoveryCodeRequest.addEventListener('load', function (event) {
    cb(JSON.parse(recoveryCodeRequest.responseText))
  })
  recoveryCodeRequest.send()
}

module.exports = {
  login: login,
  loginWithCodeBundle: loginWithCodeBundle,
  logout: logout,
  getAccount: getAccount,
  addEmail: addEmail,
  editUser: editUser,
  verifyMail: verifyMail,
  getRecoveryCode: getRecoveryCode,
  getEmailAddresses: getEmailAddresses,
  getContactsForUser: getContactsForUser,
  shareIdentity: shareIdentity,
  getIDsForUser: getIDsForUser,
  addContact: addContact,
  deleteShare: deleteShare,
  consent: consent,
  getConsentInformation: getConsentInformation,
  isLoggedIn: isLoggedIn,
  createClient: createClient,
  getOIDCClients: getOIDCClients
}
