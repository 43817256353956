<template>
<div id="centerwrapper">
    <h3 class="font-bold text-xl">OIDC URLs:</h3>
    <table>
        <thead>
            <th style="width: 50%">Name</th>
            <th>URL</th>
        </thead>
        <tbody>
            <tr>
                <td class="font-bold">Discovery Document Url</td>
                <td>https://aauth.me/.well-known/openid-configuration</td>
            </tr>
            <tr>
                <td class="font-bold">Authorization Token Endpoint</td>
                <td>https://aauth.me/api/auth/oidc</td>
            </tr>
            <tr>
                <td class="font-bold">Token Endpoint</td>
                <td>https://aauth.me/api/auth/token</td>
            </tr>
        </tbody>
    </table>
     <h3 class="font-bold text-xl mt-5">OIDC-Clients</h3>
         <template v-for="client in oidcclients" :key="client.name">
           <div class="mt-4">
             <span class="font-bold text-2xl">{{client.name}}</span><button class="bg-white px-2 font-bold text-sm border-2 border-red-500 rounded-lg ml-5">Entfernen</button>
             <div class="ml-3 mt-2">
               <table>
                 <tr><td class="font-bold">Client ID:</td><td style="font-weight: bold">{{client.client_id}}</td></tr>
                 <tr><td class="font-bold">Client Secret:</td><td style="font-weight: bold">{{client.client_secret}}</td></tr>
                 <tr><td class="font-bold">Redirect URL:</td><td style="font-weight: bold">{{client.redirect_uri}}</td></tr>
                 <tr hidden><td class="font-bold">Registration:</td><td>{{client.registration}}</td></tr>
              </table>

             </div>
           </div>
         </template>
         <div class="inline-block">
          <span class="font-bold">Name:</span><br>
          <input class="border-b border-black" v-model="newClientName" placeholder="Meine Webanwendung"><br>
          <span class="font-bold">Redirect-URL:</span><br>
          <input class="border-b border-black w-96" v-model="redirect" placeholder="https://app.de/callback"><br>
          <div class="w-full text-right mt-4">
          <button class="px-2 py-1 bg-white border border-green-400 rounded-lg font-bold" @click="createClient()">Client erstellen</button>
           </div>
           </div>
</div>
</template>

<script>
// @ is an alias to /src
const AAUth = require('@/AAuth.js')

export default {
  name: 'Home',
  components: {
  },
  data () {
    return {
      loggedIn: true,
      oidcclients: [],
      newClientName: '',
      registration: 'OPEN',
      redirect: ''
    }
  },
  methods: {
    getOIDCClients () {
      const vm = this
      AAUth.getOIDCClients((clients) => {
        vm.oidcclients = clients
      })
    },
    createClient () {
      const vm = this
      AAUth.createClient({
        name: vm.newClientName,
        registration: vm.registration,
        redirect_uri: vm.redirect
      }, (response) => {
        if (response.status === 'success') {
          vm.getOIDCClients()
          vm.newClientName = ''
          vm.redirect = ''
          vm.registration = 'OPEN'
        }
      })
    }
  },
  created: function () {
    this.getOIDCClients()
  }
}
</script>
<style>
#logo {
  width: 250px;
  height: 250px
}
html {
  background: #f1f1f1
}
table{
  display: inline-block;
}
#oidctable td {
  border: 1px solid black;
}
td{
  border: 1px solid black;
  padding: 2px;
}
button{
  display: inline-block;
}
#centerwrapper{
  max-width: 80%;
  text-align:left;
  display: inline-block;
  background: white;
  border-radius: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 30px;
}
.deleteClient, .createClient{
  margin-left: 15px;
  background: red;
  padding: 5px;
  font-weight: bold;
  border: unset;
  border-radius: 5px;
}
.createClient{
  background: green;
}
a{
  color:unset;
}
</style>
