
<template>
<h2>Enter App-Code below</h2>
  <input v-model="pin" type="number" style="font-size: 80px; width: 200px" autofocus>
</template>
<script>

const AAUth = require('@/AAuth.js')
export default {
  name: 'CodeBundle',
  data () {
    return {
      pin: ''
    }
  },
  methods: {
    login (pin) {
      AAUth.loginWithCodeBundle(this.$route.query.code, pin, (response) => {
        if (response.status === 'success') {
          if (response.redirectTo !== undefined) {
            window.location = process.env.VUE_APP_HTTP_URL+'' + response.redirectTo
          } else {
            window.location = '/me'
          }
        }
      })
    }
  },
  watch: {
    pin: function (val, oldVal) {
      if (val.toString().length === 4) {
        this.login(val)
      }
    }
  }
}
</script>
