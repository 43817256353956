<template>
  <div id="headerwrapper">
    <div style="width: 10%; display: inline-block" class="align-top">
    <img alt="AAuth logo" id="logo"  class="inline-block" src="../assets/cleanLogo.png" onclick='window.location="/"'>
    </div>
    <div style="width: 80%; display: inline-block; text-align: center; vertical-align: top">
      <h1 class="font-bold text-2xl sm:text-4xl">{{title}}</h1>
    </div>
    <div v-if="loggedIn" style="width: 10%; display: inline-block; text-align: right;" class="pr-5 pt-3 cursor-pointer">
      <font-awesome-icon icon="fa-sharp fa-right-to-bracket" size="2x" @click="logout()" />
    </div>
  </div>
</template>

<script>
const AAUth = require('@/AAuth.js')

export default {
  name: 'Header',
  props: {
    loggedIn: Boolean,
    title: String
  },
  methods: {
    logout () {
      AAUth.logout(() => {
        window.location = '/'
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.user-shield, .info-circle, .code{
  height: 130px;
  width: 130px;
  color: #393939
}
.navIcon{
  padding: 30px;
  display: inline-block;
}
h3{
  display: block;
}
h1{
  margin-top: 10px;
}
#logo, .sign-out-alt {
  width: 60px;
  height: 60px;
  display: inline;
  cursor: pointer;
}
#headerwrapper{
  background: white;
  text-align: left;
  width: 100%;
  height: 60px;
  margin-bottom: 50px;
}
</style>
