
<template>
    <Header title="Log in to AAuth" />
  <QR :value="qrcode" :size="200" ></QR>
  <h1 class="font-bold text-lg mt-5">Scan this code with your AAuth smartphone app to log in</h1>
            <span class="mt-4"> Don't have the app? Download it using the button below </span><br>
            <a href="https://play.google.com/store/apps/details?id=me.aauth.android"><img id="badge" alt="Get it on Google Play" src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"></a>
</template>
<script>

import QR from '@/components/QR.js'
import Header from '@/components/Header'
const AAuth = require('@/AAuth.js')
export default {
  name: 'Login',
  components: {
    Header,
    QR
  },
  data () {
    return {
      qrcode: ''
    }
  },
  methods: {
    startSocket () {
      const comp = this
      this.connection = new WebSocket(process.env.VUE_APP_WS_URL+'/login')
      this.connection.onmessage = function (event) {
        let data=JSON.parse(event.data)
        if (data.type==="login") {
          comp.qrcode = 'aauth:' + data.type+":"+data.token
        } else if (data.type==="authCode") {
          comp.getToken(data.token)
        }
      }
    },
    getToken (logintoken) {
      AAuth.login(logintoken, this.$route.query.s, (request) => {
        const jsonresponse = JSON.parse(request.responseText)
        if (jsonresponse.status === 'success') {
          if (jsonresponse.redirectTo !== undefined) {
            window.location = process.env.VUE_APP_HTTP_URL+'' + jsonresponse.redirectTo
          } else {
            window.location = '/me'
          }
        }
      })
    }
  },
  created: function () {
    this.startSocket()
  }
}
</script>
<style scoped>

#badge{
  width: 300px;
}
  </style>
