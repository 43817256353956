<template>
    <div class="w-full">
        <div style="text-align: center">
        <table id="contacttable" style="text-align: left" v-if="contacts.length > 0">
            <thead>
                <th style="width: 80%">Nickname</th>
                <th style="width: 15%;text-align: center">Actions</th>
            </thead>
            <tbody id="companybody">
                <template v-for="contact in contacts" :key="contact.nickname">
                  <tr>
                    <td>{{ contact.nickname }}</td>
                    <td style="text-align: center"><font-awesome-icon @click="deleteContact" :id="contact.uuid" icon="fa-trash-alt"/></td>
                  </tr>
                </template>
            </tbody>
        </table>
        <div v-else>
          <span class="font-bold text-xl">Du hast aktuell keine Kontakte.</span><br>
        </div>
          <br><button class="mt-4" onclick="window.location='/me/addContact'">Kontakt hinzufügen</button>
        </div>
    </div>
</template>

<script>

export default {
  name: 'Contacts',
  props: {
    contacts: Array
  },
  methods: {
    deleteContact (event) {
      console.log('debug')
    },
    shareAccessWithContact (event) {
      console.log('debug')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 10px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
table { border-collapse: separate; }
tr{
  margin: 5px;
}
svg{
  cursor: pointer;
  width: 30px;
  height: 30px;
}
td{
  padding: 5px;
  margin: 0px;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
}
td:first-child { border-left: solid 1px gray;border-top-left-radius: 10px;border-bottom-left-radius: 10px; }
td:last-child { border-right: solid 1px gray;border-top-right-radius: 10px; border-bottom-right-radius: 10px; }

.user-shield, .info-circle, .code{
  height: 130px;
  width: 130px;
  cursor: pointer;
  color: #393939;
}
.share-square, .trash, .edit {
  height: 20px;
  width: 20px;
  color: #393939;
  cursor: pointer;

}
.navIcon{
  padding: 30px;
  display: inline-block;
}
h3{
  display: block;
}
.section {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  text-align: left;

  box-sizing: border-box;
}
</style>
