<template>
  <div class="home sm:p-4">
    <Header title="Accountauswahl" :loggedIn="true" />
    <div style="width: 100%; text-align: center">
      <div
        class="w-full sm:w-2/3 lg:w-1/3"
        style="
          text-align: left;
          display: inline-block;
          background: white;
          border-radius: 15px;
          padding: 20px;
        "
      >
        <div style="width: 100%; text-align: center">
          <h2 class="font-bold text-3xl">{{ appName }}</h2>
        </div>
        <div v-if="existing.length > 0">
        <h3 class="font-bold mt-4">
          Folgende Accounts hast du mit diesem Dienst bereits genutzt:
        </h3>
        <div class="w-full text-center">
          <div v-for="id in existing" :key="id.uuid" class="py-2">
            <div
              class="
                inline-block
                border-2 border-black
                rounded-lg
                px-3
                cursor-pointer
              "
              @click="consent(id.uuid)"
            >
              <span class="break-normal break-words font-bold">{{
                id.mail
              }}</span>
            </div>
            <br />
          </div>
        </div>
        </div>
        <div v-if="potential.length > 0">
          <h3 class="font-bold mt-2 mb-5">
            Erstelle einen Account mit einer dieser Mailadressen:
          </h3>
          <div class="w-full text-center">
            <div
              v-for="id in potential"
              :key="id.uuid"
              class="
                block
                
              "
              @click="consent(id.uuid)"
            >
              <span class="break-normal break-words font-bold border-2 border-black
                rounded-lg
                px-3
                cursor-pointer inline-block my-2 w-5/6">{{
                id.mail
              }}</span>
            </div>
          </div>
        </div>
        <div v-if="potential.length == 0 && existing.length == 0" class="mt-3 font-bold">
          Du hast keine verifizierten Mailadressen, dieser Dienst benötigt jedoch eine Mailadresse zur Zuordnung der Accountdaten.
          <div class="w-full text-center">
          <button class="mt-3" @click="window.location=callback">Zurück zur Anwendung</button>
          </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
const AAUth = require("@/AAuth.js");
let redirectTo, clientId;
export default {
  name: "Me",
  components: {
    Header,
  },
  data() {
    return {
      title: "Grant Access",
      appName: "",
      existing: [],
      potential: [],
      callback:"",
      window:window
    };
  },
  methods: {
    consent: function (id) {
      console.log("this is consent speaking");
      console.log(id);
      AAUth.consent(id, clientId, (response) => {
        if (response.status === "success") {
          window.location = redirectTo + "&consent=" + response.consent;
        }
      });
    },
    getConsentInformation() {
      const vm = this;
      AAUth.getConsentInformation(this.$route.query.cr, (response) => {
        if (response.status === "success") {
          vm.appName = response.client_name;
        }
        vm.existing = response.ids.existing;
        vm.potential = response.ids.potential;
        clientId = response.client_id;
        redirectTo = response.redirectTo;
        vm.callback=response.callbackurl+"error=No Identity available&error_description=The user has no email addresses"
      });
    },
  },
  created: function () {
    this.getConsentInformation();
  },
};
</script>
<style>
#logo {
  width: 250px;
  height: 250px;
}
html {
  background: #f1f1f1;
}
.tablecell {
  display: inline-block;
  font-weight: bold;
  word-break: break-all;
  vertical-align: middle;
}
</style>
