<template>
    <div class="text-left">
        <div style="width: 100%">
            <div class="tablecell" style="width: 40%;">
                Dienst
            </div>
            <div class="tablecell" style="width: 50%;">
                E-Mailadresse
            </div>
            <div class="tablecell" style="width: 10%; text-align:center">
                Optionen
            </div>
        </div>
        <template v-for="id in ids" :key="id.uuid">
            <div style="width: 100%; border: 1px solid black; border-radius: 7px; margin-bottom: 3px" class="p-1">
                <div class="tablecell" style="width: 40%;">
                    {{ id.app }}
                </div>
                <div class="tablecell" style="width: 50%;">
                    <span v-if="id.email">{{ id.email }}</span>
                    <span v-else>Identität ohne Mailadresse</span>
                </div>
                <div class="tablecell" style="width: 10%;text-align:center">
                    <font-awesome-icon v-if="id.shareable" @click="id.showShare = !id.showShare" :id="id.uuid" icon="fa-share" class="mx-2"/>
                    <font-awesome-icon @click="deleteIdentity" :id="id.uuid"  class="mx-2" icon="fa-trash-alt" />
                </div>
            </div>
            <div v-show="id.showShare" style="margin-left: 50px; width: 90%">
                      <div class="tablecell" style="width: 80%;">
                          Geteilt mit
                      </div>
                      <div class="tablecell" style="width: 20%; text-align:center">
                          Optionen
                      </div>
                  <template v-for="share in id.shares" :key="share.uuid">
                      <div style="border: 1px solid black; border-radius: 10px; margin: 5px">
                          <div class="tablecell" style="width: 80%;">
                              {{share.sharedWith}}
                          </div>
                          <div class="tablecell" style="width: 20%;text-align:center">
                              <icon @click="deleteShare" :id="share.uuid" name="trash-alt" />
                          </div>
                      </div>
                  </template>
                  <div style="border: 1px solid black; border-radius: 10px; margin: 5px">
                          <div class="tablecell" style="width: 80%;">
                              <select>
                                  <template v-for="contact in contacts" :key="contact.uuid">
                                    <option :value="contact.uuid">{{contact.nickname}}</option>
                                    </template>
                              </select>
                          </div>
                          <div class="tablecell" style="width: 20%;text-align:center">
                              <font-awesome-icon @click="shareIdentity" :refID="id.uuid" icon="fa-plus-circle" />
                          </div>
                      </div>
            </div>
        </template>
    </div>
</template>
<script>
const AAuth = require('@/AAuth.js')

export default {
  name: 'Identities',
  props: {
    contacts: Array
  },
  data () {
    return {
      ids: []
    }
  },
  methods: {
    deleteShare (event) {
      let target = event.target
      if (target.tagName === 'path')target = target.parentNode
      const vm = this
      AAuth.deleteShare(target.id, () => {
        vm.getIDs()
      })
    },
    deleteIdentity (event) {
      console.log('debug')
    },
    showUsage (event) {
      console.log('debug')
    },
    shareIdentity (event) {
      console.log(event.target.parentNode.parentNode.firstChild.firstChild.value)
      console.log(event.target.getAttribute('refID'))
      const vm = this
      AAuth.shareIdentity(event.target.getAttribute('refID'), event.target.parentNode.parentNode.firstChild.firstChild.value, () => {
        vm.getIDs()
      })
    },
    getIDs () {
      const vm = this
      AAuth.getIDsForUser((response) => {
        if (response.status !== 'error') {
          response.forEach(id => {
            id.showShare = false
          })
          vm.ids = response
        }
      })
    }
  },
  created: function () {
    this.getIDs()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
select, select:focus{
  border: unset;
  outline: none;
}
.tablecell{
  display: inline-block;
  font-weight: bold;
  word-break: break-all;
  vertical-align: middle;
}
</style>
